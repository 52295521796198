import {
  AUCTION_STATUS_IN_PROGRESS,
  PREDICAMENT_STATE_NO,
  PREDICAMENT_STATE_YES,
  SEARCH_TYPE_CARE,
} from "core/consts";
import { Auction, DeepNullableObj } from "core/types";
import { getUnixTime } from "date-fns";
import { defaultsDeep } from "lodash";
import { MockFn, createMock } from "./index";
import mockCareprovider from "./mockCareprovider";
const start_date = getUnixTime(new Date(2021, 10, 1));

const mockAuction: MockFn<Partial<DeepNullableObj<Auction>>> = (args) =>
  defaultsDeep(
    args,
    createMock(
      "Auction",
      args,
      (shallow): DeepNullableObj<Auction> => ({
        __typename: "Auction",
        id: 1,
        action_required_by_user: false,
        assessment_variant: null,
        share_patient_data_automatically: false,
        additional_specializations: null,
        country_wide_search: false,
        candidates_status: null,
        all_contacted: null,
        all_negative: null,
        assessment_completed: true,
        cancel_auction: {
          reason: null,
          description: null,
        },
        cancel_validation_reason: null,
        created_at: null,
        max_accepts: null,
        is_stale: false,
        last_status_change: null,
        medical_supplies: {
          prescription_issued: null,
          prescription_issued_description: null,
          delivery_address_type: null,
          additional_information: null,
          delivery_address: {
            city: null,
            encrypted_house_number: null,
            latitude: null,
            longitude: null,
            radius_in_meter: null,
            street: null,
            zipcode: null,
            floor: null,
          },
          products: [],
        },
        new_responses: 0,
        patient: null,
        patient_id: null,
        payers: {
          civil_servants_aid: null,
          free_from_supplementary_payment_state: PREDICAMENT_STATE_YES,
          further_information: null,
          insurance: null,
          other_payer: null,
          public_accident_insurance: null,
          public_pension: null,
          selected_payment_method: null,
          self_payer: null,
          social_service_department: null,
          supplementary_insurance_state: PREDICAMENT_STATE_YES,
          supplementary_insurance: null,
          supplementary_payment_state: PREDICAMENT_STATE_YES,
          supplementary_payment: null,
        },
        pending_requests: null,
        profile: {
          has_transitional_care: null,
          is_elective: false,
          is_visually_impaired: PREDICAMENT_STATE_NO,
          monitoring_needs: null,
          needs_oxygen: null,
          no_site_support_required: true,
          patient_type_updated: null,
          pick_up: { end: null, start: null },
          search_destination: {
            city: null,
            country: null,
            encrypted_house_number: {
              decrypted: null,
              content: null,
              seald_content: null,
              iv: null,
            },
            latitude: null,
            longitude: null,
            radius_in_meter: null,
            street: null,
            zipcode: null,
            floor: null,
            federal_state: null,
          },
          search_location: {
            city: "Berlin",
            encrypted_house_number: {
              decrypted: "103",
              iv: "",
              content: "",
              seald_content: "",
            },
            federal_state: null,
            floor: null,
            latitude: 52.5237568,
            longitude: 13.3801421,
            radius_in_meter: 100,
            street: "Alt-Moabit",
            zipcode: "10559",
          },
        },
        radius_in_meter: null,
        request_count: null,
        search_actions: args?.search_actions
          ? args?.search_actions.map((searchAction) => ({
              action_type: searchAction?.action_type || null,
              context: {
                disabled: searchAction?.context?.disabled || null,
                variant: searchAction?.context?.variant || null,
              },
            }))
          : null,
        search_type: SEARCH_TYPE_CARE,
        seen_by_user: null,
        services: null,
        session_key_context: {
          has_session_keys: false,
          session_key: {
            account_id: 1,
            algorithm: null,
            auction_request_id: 1,
            careprovider_id: 1,
            careseeker_id: 1,
            id: 1,
            patient_id: 1,
            session_key: null,
            updated_at: 1,
          },
        },
        seald_encryption_context: {
          id: null,
          seald_id: null,
          seald_display_id: null,
          seald_type: null,
          careseeker_id: null,
          careprovider_id: null,
          patient_id: null,
          auction_request_id: null,
          file_id: null,
        },
        solutions: [],
        specializations: [],
        start_date: start_date,
        start_date_flexible: false,
        status: AUCTION_STATUS_IN_PROGRESS,
        winner: {
          solution: null,
          auction_request_id: null,
          careprovider_name: null,
          careprovider: mockCareprovider({ ...shallow("Careprovider"), id: 6 }),
          created_at: null,
          external_provider: null,
          unknown_provider: null,
          updated_at: null,
          specialization: null,
        },
      }),
    ),
  );

export default mockAuction;
